import React, { useState, useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

const ScrollingCards = () => {
  const [hoveredTop, setHoveredTop] = useState(false);
  const [hoveredBottom, setHoveredBottom] = useState(false);
  const containerRef = useRef(null);
  const controlsTop = useAnimation();
  const controlsBottom = useAnimation();

  const cards = [
    { id: 1, title: 'Fun Time!', description: 'Exciting events are about to unfold!' },
    { id: 2, title: 'Join the Action!', description: 'Get ready for some mind-blowing activities!' },
    { id: 3, title: 'Unleash Your Talent!', description: 'Show off your skills and win prizes!' },
    { id: 4, title: 'Are You Ready?', description: 'Let’s make some unforgettable memories!' },
    { id: 5, title: 'Non-stop Entertainment!', description: 'From games to music, we’ve got it all!' },
    { id: 6, title: 'Tech Wizards Assemble!', description: 'Prepare for coding battles and tech talks!' },
    { id: 7, title: 'Creativity Unleashed!', description: 'Design, debate, and dance your heart out!' },
    { id: 8, title: 'Challenge Accepted!', description: 'Dive into competitive spirit and have fun!' }
  ];

  const doubledCards = [...cards, ...cards];

  useEffect(() => {
    const containerWidth = containerRef.current.offsetWidth;
    const animate = async () => {
      await controlsTop.start({
        translateX: [-containerWidth / 2, 0],
        transition: { duration: 20, ease: 'linear', repeat: Infinity }
      });
    };
    animate();
  }, [controlsTop]);

  useEffect(() => {
    const containerWidth = containerRef.current.offsetWidth;
    const animate = async () => {
      await controlsBottom.start({
        translateX: [0, -containerWidth / 2],
        transition: { duration: 20, ease: 'linear', repeat: Infinity }
      });
    };
    animate();
  }, [controlsBottom]);

  const Card = ({ title, description }) => (
    <div className="flex-shrink-0 w-72 h-32 flex items-center bg-white rounded-2xl p-4 mx-2 shadow-lg">
      <div className="flex flex-col justify-center">
        <h3 className="text-black text-lg font-semibold">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
    </div>
  );

  return (
    <div className="bg-black py-12 overflow-hidden pb-[200px]" ref={containerRef}>
      <motion.div
        className="flex mb-8"
        animate={controlsTop}
        onHoverStart={() => setHoveredTop(true)}
        onHoverEnd={() => setHoveredTop(false)}
      >
        {doubledCards.map((card, index) => (
          <Card key={`${card.id}-${index}`} title={card.title} description={card.description} />
        ))}
      </motion.div>

      <motion.div
        className="flex"
        animate={controlsBottom}
        onHoverStart={() => setHoveredBottom(true)}
        onHoverEnd={() => setHoveredBottom(false)}
      >
        {doubledCards.map((card, index) => (
          <Card key={`${card.id}-${index}`} title={card.title} description={card.description} />
        ))}
      </motion.div>
    </div>
  );
};

export default ScrollingCards;
