import React from "react";

const Landing2 = () => {
  return (
    <div className="w-full h-full bg-black pt-10 pb-[100px] ">
      <div className="landing2">
        <div className="bg-text-container">
    
          <div className="animate-text">
            <span className="spaner">Vibe On!&nbsp;</span>
            <span className="spaner">Vibe On!&nbsp;</span>
          </div>
          <div className="animate-text left">
            <span className="spaner">Vibe On!&nbsp;</span>
            <span className="spaner">Vibe On!&nbsp;</span>
          </div>
        </div>

        {/* Main Text Content */}
        <div className="container mx-auto px-4 md:px-12 lg:px-16 overflow-hidden h-full ">
          <div className="col">
            <h1 className="text-[35px] sm:text-[40px] lg:text-[50px] text-white font-semibold mb-6">
              Casyum ‘24
            </h1>
            <p className="text-[18px] sm:text-[20px] lg:text-[25px] text-gray-300 leading-relaxed">
Get ready for Casyum '24, the ultimate student fest at SRM Ramapuram! Whether you're into tech, performance, or just here for the vibes, we've got you covered. Compete in thrilling challenges, showcase your talents, and soak up the energy of an unforgettable event.

From tech wizards to creative performers, Casyum is the place where passion meets celebration. Make new friends, conquer the stage, and take home memories that last. With support from our incredible BCA faculty, you'll be motivated to give your best and enjoy every moment. Don’t miss your chance to be part of something big at Casyum '24!

Join us and let the fun begin!
            <br></br>
            <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing2;
