import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptopCode,
  faPuzzlePiece,
  faMusic,
  faGamepad,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
// Import images
import quizImg from "../Images/Posters/quiz.jpg";
import logoImg from "../Images/Posters/logo designing.jpg";
import paperPresentationImg from "../Images/Posters/PAPER PRESENTATION .png";
import debuggingImg from "../Images/Posters/DEBUGGING  (1).png";
import treasureHuntImg from "../Images/Posters/TREASURE HUNT POSTER.png";
import danceImg from "../Images/Posters/DANCE.png";
import fifaImg from "../Images/Posters/FIFA .jpg";
import bgmiImg from "../Images/Posters/BGMI.jpg";
import freeFireImg from "../Images/Posters/FREE FIRE.png";
import vocalVoiceImg from "../Images/Posters/vocal&voice.jpg";
import talentShowImg from "../Images/Posters/talent show.jpg";
import cookingWithoutFireImg from "../Images/Posters/COOKING WITHOUT FIRE.jpg";
import memeCreationImg from "../Images/Posters/meme creation.jpg";
import sharkTankImg from "../Images/Posters/SHARK TANK.png";  // Assuming you have this image

const NeonBackground = () => {
  return (
    <div className="absolute inset-0 overflow-hidden z-0">
      <div className="neon-text">VIBE ON!</div>
    </div>
  );
};
const Lightbox = ({ imageSrc, eventDetails, onClose }) => {
  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className="bg-gray-800 p-6 rounded-xl max-w-4xl w-full max-h-90vh overflow-y-auto"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-bold neon-text">{eventDetails.name}</h3>
          <button onClick={onClose} className="text-white text-xl">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="text-center">
          <img src={imageSrc} alt={eventDetails.name} className="w-full h-auto rounded-lg mb-4" />
          <p className="text-base text-gray-300 mb-4">Fee: {eventDetails.fee} /-</p>
          <p className="text-base text-gray-300 mb-4">Food provided: {eventDetails.food ? "Yes" : "No"}</p>
          <p className="text-base text-gray-300 mb-4">Certificates: {eventDetails.certs ? "Yes" : "No"}</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

const PhotoGridModal = ({ isOpen, onClose, event, onImageClick }) => {
  const eventImages = {
    "Technical": [
      quizImg,
      logoImg,
      paperPresentationImg,
      debuggingImg
    ],
    "Non-Technical": [
      treasureHuntImg,
      fifaImg,
      memeCreationImg,  // Added Meme Creation image
      sharkTankImg , // Added Shark Tank image,
      vocalVoiceImg,       // Added Vocal & Voice Singing image
      talentShowImg,       // Added Talent Showcase image
      cookingWithoutFireImg // Added Cooking Without Fire image
    ],
    "Dancing": [
      danceImg
    ],
    "Gaming": [
      bgmiImg,
      freeFireImg
    ],
  
  };
  
  

  const images = eventImages[event.type] || [];
  const eventDetails = event.details || [];

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center p-4"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-black opacity-0.5 p-6 rounded-xl z-100 max-w-4xl w-full max-h-90vh overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-2xl font-bold neon-text">{event.type} Events</h3>
              <button onClick={onClose} className="text-white text-xl">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {images.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Event ${index + 1}`}
                  className="w-full h-auto rounded-lg cursor-pointer"
                  onClick={() => onImageClick(src, eventDetails[index])}  // Open lightbox on image click
                />
              ))}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};


const EventCard = ({ event, index, onOpenModal }) => {
  return (
    <motion.div 
      className="neon-card p-6 md:p-8 rounded-xl md:rounded-2xl backdrop-blur-md bg-opacity-10 bg-black shadow-lg border border-opacity-30 border-white overflow-hidden relative cursor-pointer"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.98 }}
      onClick={() => onOpenModal(event)}
    >
      <div className={`absolute inset-0 bg-gradient-to-br ${event.color} opacity-10`}></div>
      <div className="relative z-10">
        <motion.div 
          className="text-4xl md:text-5xl mb-4 md:mb-6"
          // whileHover={{ rotateY: 360 }}
          transition={{ duration: 0.5 }}
        >
          <FontAwesomeIcon icon={event.icon} className={`neon-icon text-${event.color.split('-')[1]}`} />
        </motion.div>
        <h3 className="text-2xl md:text-3xl font-bold mb-2 md:mb-4 neon-text">{event.type}</h3>
        <h1 className="text-[20px] font-[700]">  {event.type}</h1>
      
        <p className="text-base md:text-xl text-gray-300 mb-4">{event.description}</p>
     
      </div>
    </motion.div>
  );
};

const EventSection = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [lightbox, setLightbox] = useState(null); // To handle the lightbox

  const events = [
    {
      type: "Technical",
      description: "Engage in coding challenges, hackathons, and tech workshops to showcase your skills.",
      icon: faLaptopCode,
      color: "from-blue-500 to-cyan-300",
      details: [
        { name: "Technical Quiz", fee: "250 (included in All Events pass) ", food: true, certs: true },
        { name: "Logo Designing", fee: "250 (included in All Events pass)", food: true, certs: true },
        { name: "Paper Presentation", fee: "250 (included in All Events pass)", food: true, certs: true },
        { name: "Debugging", fee: "250 (included in All Events pass)", food: true, certs: true }
      ]
    },
    {
      type: "Non-Technical",
      description: "Participate in quizzes, debates, and creative contests to demonstrate your diverse talents.",
      icon: faPuzzlePiece,
      color: "from-purple-500 to-pink-500",
      details: [
        { name: "Treasure Hunt", fee: "250 (included in All Events pass)", food: true, certs: true },
        { name: "FIFA", fee: "250 (included in All Events pass)", food: true, certs: true },
        { name: "Meme Creation", fee: "250 (included in All Events pass)", food: true, certs: true },
        { name: "Shark Tank", fee: "250 (included in All Events pass)", food: true, certs: true },
        { name: "Vocal & Voice Singing", fee: "250 (included in All Events pass)", food: true, certs: true },
        { name: "Talent Showcase", fee: "250 (included in All Events pass)", food: true, certs: true },
        { name: "Cooking Without Fire", fee: "250 (included in All Events pass)", food: true, certs: true }
      ]
    },
    {
      type: "Dancing",
      description: "Show off your moves in solo and group dance competitions across various styles.",
      icon: faMusic,
      color: "from-red-500 to-yellow-500",
      details: [
        { name: "Banging Beats (Group Dance)", fee: "500 (dance pass)", food: false, certs: true },
        { name: "Adaptune", fee: "150", food: false, certs: true }
      ]
    },
    {
      type: "Gaming",
      description: "Compete in esports tournaments and casual gaming events for exciting prizes.",
      icon: faGamepad,
      color: "from-green-500 to-emerald-300",
      details: [
        { name: "BGMI", fee: "250 (included in gaming pass)", food: false, certs: true },
        { name: "Free Fire", fee: "250  (included in gaming pass)", food: false, certs: true }
      ]
    }
  ];
  

  const openLightbox = (imageSrc, eventDetails) => {
    setLightbox({ imageSrc, eventDetails });
  };

  return (
    <section className="relative bg-black text-white py-16 md:py-24 overflow-hidden" id="events">
      <NeonBackground />
      <div className="container mx-auto px-4 relative z-10">
        <motion.h2 
          className="text-4xl md:text-6xl mb-10 md:mb-16 text-center neon-text"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Event Categories
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12 mb-12">
          {events.map((event, index) => (
            <EventCard 
              key={index} 
              event={event} 
              index={index} 
              onOpenModal={setSelectedEvent}
            />
          ))}
        </div>
        <div className="text-center">
          <a href="#pricing">
          <button 
            className="neon-button px-8 py-3 rounded-full text-lg md:text-xl"
            // onClick={() => alert("Registering for all events")}
          >
            Register Now
          </button>
          </a>
        </div>
      </div>
      <PhotoGridModal 
        isOpen={!!selectedEvent} 
        onClose={() => setSelectedEvent(null)} 
        event={selectedEvent || {}}
        onImageClick={openLightbox}  // Handle image click
      />
      {lightbox && (
        <Lightbox
          imageSrc={lightbox.imageSrc}
          eventDetails={lightbox.eventDetails}
          onClose={() => setLightbox(null)}
        />
      )}
    </section>
  );
};


export default EventSection;