import React, { useState, useEffect } from "react";
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion, AnimatePresence } from "framer-motion";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../App.css";
import Casyum from '../Images/caesyu-logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
SwiperCore.use([EffectCoverflow, Pagination]);

const Landing = () => {
  const [timeLeft, setTimeLeft] = useState({});
  const [currentText, setCurrentText] = useState(0);
  const texts = [
    "Bring on the fun!",
    "To find like-minded friends.",
    "Let the games begin!",
    "Party with the best!",
    "Get ready to vibe!"
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      const eventDate = new Date('2024-09-21T08:00:00+05:30').getTime();
      const now = new Date().getTime();
      const difference = eventDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        clearInterval(timer);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    const textInterval = setInterval(() => {
      setCurrentText((prev) => (prev + 1) % texts.length);
    }, 3000);

    return () => {
      clearInterval(timer);
      clearInterval(textInterval);
    };
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <canvas className="orb-canvas absolute right-0 bottom-0 w-2/3 h-2/3"></canvas>
      <div className="absolute top-0 left-0 right-0 flex justify-between items-center p-4">
      </div>
 
      <div className="relative w-full h-full flex flex-col justify-center items-start px-8 md:px-16 lg:px-24">
      <img src={Casyum} alt="Right Logo" className="w-[220px] md:w-[180px] mb-10    md:hidden" />
        <h1 className="text-6xl md:text-7xl lg:text-8xl text-white font-bold mb-4">
          Jump In
        </h1>
        <motion.p
          key={currentText}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          className="text-3xl md:text-4xl lg:text-5xl   text-gradient   font-light mb-8"
        >
          {texts[currentText]}
        </motion.p>
        <div className="flex space-x-6 md:space-x-8 mb-8">
          {Object.entries(timeLeft).map(([label, value]) => (
            <div key={label} className="text-center">
              <AnimatePresence mode="popLayout">
                <motion.span
                  key={value}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="text-4xl md:text-5xl lg:text-6xl font-bold text-white block"
                >
                  {value.toString().padStart(2, '0')}
                </motion.span>
              </AnimatePresence>
              <span className="text-sm text-gray-400 uppercase">{label}</span>
            </div>
          ))}
        </div>
        <div className="flex gap-5">
        <a href="#events">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}

          className="text-white text-lg border border-white px-8 py-3 rounded-full hover:bg-white hover:text-black transition-all duration-300"
        >
          Join the Craze
        </motion.button>
        </a>
     
        </div>
      </div>
    </div>
  );
};

export default Landing;