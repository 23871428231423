import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import logo from '../Images/caesyu-logo.png';
import {motion} from 'framer-motion'
const GlassmorphicFooter = () => {
  const styles = {
    neonStack: {
      width: '100%',
      height: '12px',  // You can adjust the height as needed
    },
    neonPink: {
      backgroundColor: '#ff007f',  // Neon pink color
    },
    neonOrange: {
      backgroundColor: '#ff9900',  // Neon orange color
    },
    footer: {
      width: '100%',

      padding: '3rem 0',
      background: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)',
      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
      boxShadow: '0 -4px 6px rgba(0, 0, 0, 0.1)',
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '120px',
      width: '120px',
      margin: '0 auto',
      // backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '50%',
      padding: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    socialIcons: {
      display: 'flex',
      justifyContent: 'center',
      gap: '1.5rem',
      marginTop: '1rem',
    },
    icon: {
      color: 'white',
      fontSize: '1.5rem',
      transition: 'color 0.3s ease',
    },
    link: {
      color: 'white',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  };

  return (
    < div >
      {/* Neon Pink and Neon Orange Stack */}
      <div style={{ ...styles.neonStack, ...styles.neonPink }}></div>
      <div style={{ ...styles.neonStack, ...styles.neonOrange }}></div>
      <a href=" https://chat.whatsapp.com/KzETL2cBXJn61DKsLqHAPH" target='_blank' className='fixed right-10 z-50 bottom-10'>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}

          className="text-white text-lg border border-white px-5 py-5 hover:text-green-500 rounded-full hover:bg-white hover:text-black transition-all duration-300 flex gap-2 items-center"
        >
          <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon>
         
        </motion.button>
        </a>
      {/* Glassmorphic Footer */}
      <footer style={styles.footer} className='lg:max-h-[400px]'> 
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <div style={styles.logoContainer}>
                <img src={logo} alt="Logo" className='absolute w-[250px]' />
              </div>
              <Typography variant="body2" style={{ color: 'white', textAlign: 'center', marginTop: '1rem' }}>
                Organized by the BCA Department of SRM RMP. Join us for an unforgettable event!
              </Typography>
              <div style={styles.socialIcons}>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebook} style={styles.icon} className="hover:text-blue-600" />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faTwitter} style={styles.icon} className="hover:text-blue-400" />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faInstagram} style={styles.icon} className="hover:text-pink-600" />
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
             
            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="h6" style={{ color: 'white', marginBottom: '1rem' }}>For Any Queries or Assistance</Typography>
              <Typography variant="body2" style={{ color: 'white', marginBottom: '0.5rem' }}>
                Feel free to reach out to us for any support during the event. We are here to help!
              </Typography>
             
              <Typography variant="body2" style={{ color: 'white', marginBottom: '0.5rem' }}>
                Male Support: +91 86102 07771 (Dinesh)
              </Typography>
              <Typography variant="body2" style={{ color: 'white', marginBottom: '0.5rem' }}>
                Female Support:+91 86104 96707 (Minnu)
              </Typography>
            
            </Grid>
          </Grid>
          <Typography variant="body2" style={{ color: 'white', marginTop: '2rem', textAlign: 'center' }}>
            © 2024 Casyum Team - BCA SRM RMP. All rights reserved.
          </Typography>
        </Container>
      </footer>
    </div>
  );
};

export default GlassmorphicFooter;
