import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGamepad,
  faStar,
  faMusic,
  faTimes,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { TextField, Button, IconButton, Box, Typography, Checkbox } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ref, push, set } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from './firebase'; // Ensure this import is correct
import { toast, Toaster } from 'react-hot-toast';
import { 
  FormControl, 
  RadioGroup, 
  FormControlLabel, 
  Radio 
} from '@mui/material';
import axios from 'axios';
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
    },
  },
});

const glassMorphStyle = {
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '16px',
  padding: '24px',
  color: 'white',
};

const NeonBackground = () => {
  return (
    <div className="absolute inset-0 overflow-hidden z-0">
      <div className="neon-text">Let's Go!</div>
    </div>
  );
};

const EventCard = ({ event, index, onOpenModal }) => {
  return (
    <motion.div 
      className="neon-card p-6 md:p-8 rounded-xl md:rounded-2xl backdrop-blur-md bg-opacity-10 bg-black shadow-lg border border-opacity-30 border-white overflow-hidden relative cursor-pointer flex flex-col h-full"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.98 }}
      // onClick={() =>
      //   {
        
      //     if(event.type!=="Dancing"){
      //       onOpenModal(event);
      //       }}}
    >
      <div className={`absolute inset-0 bg-gradient-to-br ${event.color} opacity-10`}></div>
      <div className="relative z-10 flex flex-col h-full">
        <motion.div 
          className="text-4xl md:text-5xl mb-4 md:mb-6"
          whileHover={{ rotateY: 360 }}
          transition={{ duration: 0.5 }}
        >
          <FontAwesomeIcon icon={event.icon} className={`neon-icon text-${event.color.split('-')[1]}`} />
        </motion.div>
        <h3 className="text-2xl font-bold mb-4 text-white">{event.type}</h3>
        <p className="text-base md:text-xl text-gray-300 mb-4">{event.description}</p>
        <ul className="text-sm md:text-base text-gray-300 mb-4 list-disc list-inside flex-grow">
          {event.details.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
        <button 
          className="neon-button px-8 py-3 rounded-full text-lg md:text-xl mt-auto"
          // onClick={(e) => {
          //   e.stopPropagation();
          //   if(event.type!=="Dancing"){
          //   onOpenModal(event);
          //   }
          // }}
        >
          Registeration Closed
        </button>
      </div>
    </motion.div>
  );
};

const TermsAndConditionsCard = ({ isOpen, onClose, onAccept }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <ThemeProvider theme={theme}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-90  md:bg-opacity-80 z-50 flex items-center justify-center p-4"
            onClick={onClose}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              style={glassMorphStyle}
              className="max-w-2xl w-full max-h-90vh overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5" component="h3">Terms and Conditions</Typography>
                <IconButton onClick={onClose} size="small">
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Box>
              <Typography variant="body1" mb={4}>
              By registering for Casyum '24, you agree to abide by all event rules and conditions. Please review the <a href="https://drive.google.com/file/d/1hTha72Zi4Nytgf6aOyj-5q8wtdE75srY/view?usp=sharing" target="_blank" className="underline font-[600]">Rulebook </a> before proceeding. Your participation in the event confirms your acceptance of these terms.


              </Typography>
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button variant="outlined" onClick={onClose}>Decline</Button>
                <Button variant="contained" onClick={onAccept}>Accept</Button>
              </Box>
            </motion.div>
          </motion.div>
        </ThemeProvider>
      )}
    </AnimatePresence>
  );
};

const PaymentCard = ({ isOpen, onClose, onAccept, event }) => {
  const [paymentType, setPaymentType] = useState(event.type === 'Dancing' ? 'group' : '');
  const [teamName, setTeamName] = useState('');
  const [teamLeaderName, setTeamLeaderName] = useState('');
  const [skipPayment, setSkipPayment] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    if (event.type === 'Dancing') {
      setPaymentType('group');
    }
  }, [event.type]);
  const bankDetails = `
    SRMIST - FSH BANK ACCOUNT DETAILS
    BANK NAME: CITY UNION BANK
    ACCOUNT NAME: SRMIST
    ACCOUNT NUMBER: 500101012921999
    IFSC CODE: CIUB0000517
    BRANCH: RAMAPURAM
  `;

  const getEventFee = () => {
    switch(event.type) {
      case 'Gaming':
        return '₹250 per squad';
      case 'Dancing':
    
        return paymentType === 'adaptune' ? '₹100 per person' : '₹500 per team';
      case 'All Events':
        return '₹250 per person per event';
      default:
        return '';
    }
  };

  const handleProceed = () => {
    setError('');
    if (event.type === 'Gaming') {
      if (!teamName || !teamLeaderName) {
        setError('Please fill in both Team Name and Team Leader Name.');
        return;
      }
    } else if (event.type === 'Dancing') {
      if (paymentType === 'group' && (!teamName || !teamLeaderName)) {
        setError('Please fill in both Team Name and Team Leader Name for Group Dance.');
        return;
      } 
    }
    onAccept({ paymentType, teamName, teamLeaderName, skipPayment });
  };

  const handleClose = () => {
    setPaymentType(event.type === 'Dancing' ? 'group' : '');
    setTeamName('');
    setTeamLeaderName('');
    setSkipPayment(false);
    setError('');
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <ThemeProvider theme={theme}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-90  md:bg-opacity-80 z-50 flex items-center justify-center p-4"
            onClick={handleClose}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              style={glassMorphStyle}
              className="max-w-2xl w-full max-h-90vh overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5" component="h3">Payment for {event.type}</Typography>
                <IconButton onClick={handleClose} size="small">
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Box>
              {!skipPayment && (
                <>
                  <Typography variant="body1" mb={4}>
                    Please pay {getEventFee()} to the following bank account:
                  </Typography>
                  <Typography variant="body2" mb={4} style={{ whiteSpace: 'pre-line' }}>
                    {bankDetails}
                  </Typography>
                </>
              )}
              {event.type === 'Gaming' && (
                <>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Team Name"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Team Leader Name"
                    value={teamLeaderName}
                    onChange={(e) => setTeamLeaderName(e.target.value)}
                    required
                  />
                </>
              )}
              {event.type === 'Dancing' && (
                <>
                  <FormControl component="fieldset" margin="normal">
                    <RadioGroup
                      aria-label="dance-type"
                      name="dance-type"
                      value={paymentType}
                      onChange={(e) => setPaymentType(e.target.value)}
                    >
                      <FormControlLabel value="group" control={<Radio />} defaultChecked label="Group Dance" />
                      <FormControlLabel value="adaptune" control={<Radio />} label="Adaptune" />
                    </RadioGroup>
                  </FormControl>
                  {paymentType === 'group' && (
                    <div>
                  <TextField
                    fullWidth
                    margin="normal"
                    label={paymentType === 'adaptune' ? "Your Name" : "Team Name"}
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    required
                  />
              
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Team Leader Name"
                      value={teamLeaderName}
                      onChange={(e) => setTeamLeaderName(e.target.value)}
                      required
                    />
                    </div>
                  )}
                </>
              )}
              {!skipPayment && (
                <Typography variant="body2" mb={4} color="error">
                  Note: Please keep a screenshot of your transaction. You will need to upload it on the next page.
                </Typography>
              )}
              {(event.type === 'Gaming' || (event.type === 'Dancing' && paymentType === 'group')) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={skipPayment}
                      onChange={(e) => setSkipPayment(e.target.checked)}
                    />
                  }
                  label="Skip payment (Team leader has already paid)"
                />
              )}
              {error && (
                <Typography variant="body2" color="error" mb={2}>
                  {error}
                </Typography>
              )}
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                <Button
                  variant="contained"
                  onClick={handleProceed}
                >
                  Proceed to Registration
                </Button>
              </Box>
            </motion.div>
          </motion.div>
        </ThemeProvider>
      )}
    </AnimatePresence>
  );
};

const RegistrationForm = ({ isOpen, onClose, event, paymentDetails,ipDetails }) => {
  const [formData, setFormData] = useState({
    name: '',
    college: '',
    branch: '',
    year: '',
    phone: '',
    email: '',
    transactionScreenshot: null,
    transactionId: '',
    interestedEvents: '', // Add this new field
  });

  const [screenshotPreview, setScreenshotPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleScreenshotChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({ ...prevData, transactionScreenshot: file }));
      setScreenshotPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log("Starting form submission");

      let screenshotUrl = null;
      if (!paymentDetails.skipPayment && formData.transactionScreenshot) {
        // Upload transaction screenshot
        const screenshotStorageRef = storageRef(storage, `screenshots/${formData.transactionScreenshot.name}`);
        await uploadBytes(screenshotStorageRef, formData.transactionScreenshot);
        screenshotUrl = await getDownloadURL(screenshotStorageRef);
      }

      // Save form data to Realtime Database
      console.log("Saving form data to Realtime Database");
      const registrationsRef = ref(db, 'registrations');
      const newRegistrationRef = push(registrationsRef);
      await set(newRegistrationRef, {
        name: formData.name,
        college: formData.college,
        branch: formData.branch,
        year: formData.year,
        phone: formData.phone,
        email: formData.email,
        screenshotUrl,
        timestamp: Date.now(),
        eventType: event.type,
        teamName: paymentDetails.teamName,
        teamLeaderName: paymentDetails.teamLeaderName,
        paymentType: paymentDetails.paymentType,
        ipDetails:ipDetails,  // Push IP details to Firebase
      });

      const firebaseId = newRegistrationRef.key;
      console.log("Realtime Database entry created with ID:", firebaseId);

      // Prepare form data to send to backend
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('college', formData.college);
      formDataToSend.append('branch', formData.branch);
      formDataToSend.append('year', formData.year);
      formDataToSend.append('phone', formData.phone);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('firebaseId', firebaseId);
      formDataToSend.append('eventType', event.type);
      formDataToSend.append('teamName', paymentDetails.teamName || '');
      formDataToSend.append('teamLeaderName', paymentDetails.teamLeaderName || '');
      formDataToSend.append('paymentType', paymentDetails.paymentType || '');
      formDataToSend.append('skipPayment', paymentDetails.skipPayment ? 'true' : 'false');
      if (screenshotUrl) {
        formDataToSend.append('screenshotUrl', screenshotUrl);
        formDataToSend.append('transactionId',formData.transactionId)
      }
      
      if (event.type === "All Events" && formData.interestedEvents) {
        formDataToSend.append('interestedEvents', formData.interestedEvents);
      }

      // Send form data to backend
      console.log("Sending data to backend");
      const response = await fetch('https://casyum-backedn.vercel.app/submit-registration', {
        method: 'POST',
        body: formDataToSend,
      });

      console.log("Backend response received");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();

      if (result.success) {
        console.log("Registration successful");
        toast.success('Registration successful! Check your email for details.');
        handleClose();
      } else {
        console.error("Backend returned error:", result.error);
        toast.error('Failed to complete registration.');
      }
    } catch (error) {
      console.error("Error during registration:", error);
      toast.error('Registration failed. Please try again.');
    } finally {
      setLoading(false);
    }

  };  const handleClose = () => {
    setFormData({
      name: '',
      college: '',
      branch: '',
      year: '',
      phone: '',
      email: '',
      transactionScreenshot: null,
      transactionId: '',
      interestedEvents: '',
    });
    setScreenshotPreview(null);
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <ThemeProvider theme={theme}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-90  md:bg-opacity-80 z-50 flex items-center justify-center p-4"
            onClick={handleClose}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              style={glassMorphStyle}
              className="max-w-2xl w-full max-h-90vh overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Typography variant="h5" component="h3">Register for {event.type}</Typography>
                <IconButton onClick={handleClose} size="small">
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Box>
              <form onSubmit={handleSubmit} className="space-y-4">
                <TextField
                  fullWidth
                  placeholder="Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                />
                <TextField
                  fullWidth
                  placeholder="College"
                  value={formData.college}
                  onChange={(e) => setFormData({ ...formData, college: e.target.value })}
                  required
                />
                <TextField
                  fullWidth
                  placeholder="Branch"
                  value={formData.branch}
                  onChange={(e) => setFormData({ ...formData, branch: e.target.value })}
                  required
                />
                <TextField
                  fullWidth
                  placeholder="Year"
                  type="number"
                  value={formData.year}
                  onChange={(e) => setFormData({ ...formData, year: e.target.value })}
                  required
                />
                <TextField
                  fullWidth
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  required
                />
                <TextField
                  fullWidth
                  placeholder="Email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  required
                />
                {!paymentDetails.skipPayment && (
                  <TextField
                    fullWidth
                    placeholder="Transaction ID"
                    value={formData.transactionId}
                    onChange={(e) => setFormData({ ...formData, transactionId: e.target.value })}
                    required
                  />
                )}
                {!paymentDetails.skipPayment && (
                  <Box>
                    <input
                      id="transactionScreenshot"
                      type="file"
                      accept="image/*"
                      onChange={handleScreenshotChange}
                      className="hidden"
                      required
                    />
                    <label htmlFor="transactionScreenshot">
                      <Button
                        variant="outlined"
                        component="span"
                        startIcon={<FontAwesomeIcon icon={faUpload} />}
                      >
                        Upload Transaction Screenshot
                      </Button>
                    </label>
                  </Box>
                )}
                {screenshotPreview && !paymentDetails.skipPayment && (
                  <Box mt={2}>
                    <img
                      src={screenshotPreview}
                      alt="Transaction Screenshot Preview"
                      className="max-w-full h-auto rounded-lg"
                      style={{ maxHeight: '200px' }}
                    />
                  </Box>
                )}
                {event.type === "All Events" && (
                  <TextField
                    fullWidth
                    placeholder="Interested Events (optional, separate with commas)"
                    value={formData.interestedEvents}
                    onChange={(e) => setFormData({ ...formData, interestedEvents: e.target.value })}
                    multiline
                    rows={2}
                  />
                )}
                <Button type="submit" variant="contained" fullWidth disabled={loading}>
                  {loading ? 'Submitting...' : 'Submit'}
                </Button>
              </form>
            </motion.div>
          </motion.div>
        </ThemeProvider>
      )}
    </AnimatePresence>
  );
};
const PricingSection = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showTerms, setShowTerms] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [ipDetails, setIpDetails] = useState(null); // New state for IP details

  useEffect(() => {
    const fetchIpDetails = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const ipAddress = response.data.ip;
        
   
        setIpDetails({ ip: ipAddress }); 
      } catch (error) {

      }
    };
  
    fetchIpDetails();
  }, []);
  
  const events = [
    {
      type: "Gaming",
      description: "BGMI and Free Fire tournaments",
      details: [
        "Fee: ₹250 per squad",
        "Food not provided",
        "Certificates and trophy for winners",
        "Participation certificate for all"
      ],
      icon: faGamepad,
      color: "from-blue-500 to-cyan-300"
    },
    {
      type: "All Events",
      description: "Access to all technical and non-technical events",
      details: [
        "Fee: ₹250 per person per event",
        "Food provided",
        "Participation certificate for all",
        "Certificates and trophy for winners"
      ],
      icon: faStar,
      color: "from-purple-500 to-pink-500"
    },
    {
      type: "Dancing",
      description: "Banging Beats - Group Dance and Adaptune",
      details: [
        "Group Dance: ₹500 per team",
        "Adaptune: ₹100 per person",
        "Food not provided",
        "Certificates and trophy for winners",
        "Participation certificate for all"
      ],
      icon: faMusic,
      color: "from-red-500 to-yellow-500"
    },
  ];

  const handleCardClick = (event) => {
    setSelectedEvent(event);
    setShowTerms(true);
  };

  const handleAcceptTerms = () => {
    setShowTerms(false);
    setShowPaymentCard(true);
  };

  const handleAcceptPayment = (details) => {
    setPaymentDetails(details);
    setShowPaymentCard(false);
    setShowRegistration(true);
  };

  const handleClosePaymentCard = () => {
    setShowPaymentCard(false);
    setSelectedEvent(null);
  };

  const handleCloseRegistration = () => {
    setShowRegistration(false);
    setSelectedEvent(null);
    setPaymentDetails(null);
  };

  return (
    <section className="relative bg-black text-white py-16 md:py-24 overflow-hidden" id="pricing">
      <NeonBackground />
      <div className="container mx-auto px-4 relative z-10">
        <h2 className="text-4xl md:text-6xl mb-12 text-center font-light">
          Choose Your Experience
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-12 mb-12">
          {events.map((event, index) => (
            <EventCard 
              key={index} 
              event={event} 
              index={index} 
              onOpenModal={() => handleCardClick(event)}
            />
          ))}
        </div>
      </div>
      <TermsAndConditionsCard 
        isOpen={showTerms} 
        onClose={() => setShowTerms(false)} 
        onAccept={handleAcceptTerms}
      />
      <PaymentCard 
        isOpen={showPaymentCard} 
        event={selectedEvent || {}}
        onClose={handleClosePaymentCard} 
        onAccept={handleAcceptPayment}
      />
      <RegistrationForm 
        isOpen={showRegistration} 
        onClose={handleCloseRegistration} 
        event={selectedEvent || {}}
        paymentDetails={paymentDetails || {}}
        ipDetails={ipDetails}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </section>
  );
};

export default PricingSection;