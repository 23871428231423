import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const timer = setInterval(() => {
      const eventDate = new Date('2024-09-21T08:00:00+05:30').getTime();
      const now = new Date().getTime();
      const difference = eventDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        clearInterval(timer);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="h-screen flex flex-col justify-center items-start p-16 bg-black relative overflow-hidden">
      <div className="z-10">
        <h1 className="text-6xl md:text-8xl font-bold text-white mb-4">Jump In</h1>
        <p className="words text-3xl md:text-5xl font-bold mb-8">Party with the best!</p>
        <div className="flex space-x-8 mb-8">
          {Object.entries(timeLeft).map(([label, value]) => (
            <div key={label} className="text-center">
              <motion.span 
                className="text-4xl md:text-6xl font-bold text-white block"
                animate={{ scale: [1, 1.1, 1] }}
                transition={{ duration: 1, repeat: Infinity }}
              >
                {value}
              </motion.span>
              <span className="text-xl text-gray-400 uppercase">{label}</span>
            </div>
          ))}
        </div>
        <button className="px-6 py-3 text-lg font-semibold text-white border border-white rounded-full hover:bg-white hover:text-black transition-colors duration-300">
          Join the Craze
        </button>
      </div>
      <div className="absolute right-0 top-0 w-2/3 h-full bg-gradient-to-bl from-yellow-500 via-red-500 to-pink-500 rounded-full blur-3xl opacity-50 transform translate-x-1/3"></div>
    </div>
  );
};

export default CountdownTimer;